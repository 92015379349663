
.row-active {
  background-color: #f0f0f0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
  padding-top: 4px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 6px 8px;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 2px 2px;
}


.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0px;
}


.ant-btn {
  /*height: 32px;*/
  padding: 3px 10px;
}

p {
  margin-top: 1px;
  margin-bottom: 1px;
}